<template>
  <div>
    <a @click="editPlanTreatment"
       v-if="rights.includes('edit_plan_treatment')"
       class="tdclickable">
      {{ object.message }}
    </a>
    <span v-else>{{ object.message }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "PatientsDatatableTDTreatmentMessage",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    editPlanTreatment() {
      if(!this.rights.includes('edit_plan_treatment')) {
        return
      }
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$bvModal.show('plan-treatment-modal')
    }
  }
}
</script>
