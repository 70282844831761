<template>
  <div>
  <a @click="editPlanTreatment"
     class="tdclickable"
     v-if="rights.includes('edit_plan_treatment')">
    {{ planTreatment.message }}
  </a>
    <span v-else>{{ planTreatment.message }}</span>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { computed, getCurrentInstance } from "vue"
import { copyObject } from "@/extensions/prototypes/prototypes"

const props = defineProps({
  object: {
    type: Object
  },
})

const store = useStore()
const instance = (getCurrentInstance())

const planTreatment = computed(() => props.object.plan_treatment)
const rights = computed(() => store.state.auth.rights)

const editPlanTreatment = () => {
  store.commit('setVisit', copyObject(planTreatment.value))
  store.commit('setPatient', copyObject(planTreatment.value.patient))
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.show('plan-treatment-modal')
}
</script>
